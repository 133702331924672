body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.navbar.sticky-top {
  top: -100px;
  transition: .5s;
}

.navbar .navbar-brand {
  height: 35px;
}

.navbar .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 20px 0;
margin-right:50px;
  color: #FFFFFF;
  font-weight: 500;
font-size:20px;
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: #FFFFFF;
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-link  {
      margin-right: 0;
      padding: 10px 0;
  }

  .navbar .navbar-nav {
      border-top: 1px solid #EEEEEE;
  }
}


.headerpage {
  display: flex;
  align-items: center;
  justify-content: center;
background-color:#FFFFFF;
}
img {
  max-width: 100%
}
.image {
  flex-basis: 40%
}
.text {
  font-size: 20px;
  padding-left: 20px;
}
ul {
list-style-type: none;
margin: 0;
padding: 0;
}

li {
display: inline;
}
