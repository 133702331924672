

@media (prefers-reduced-motion: no-preference) {
  
}

.HomeContainer{
    
 
}

@media only screen and (max-width: 915) {
  .HomeContainer{
    
      display:'flex';
      justify-content:'center';
      align-content:'center';
  }
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
